import { Component, ComponentFactoryResolver, ViewChild, ViewContainerRef } from '@angular/core';
import { NotificationService } from './services/notification/notification.service';
import { NotificationComponent } from './notification/notification.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'gat-ui';

  showNotification = false;

  notificationsComponentsRef = [];

  @ViewChild('messagecontainer', { static: true, read: ViewContainerRef }) entry: ViewContainerRef;

  constructor(
    private notificationService: NotificationService,
    private resolver: ComponentFactoryResolver
  ) {
    this.notificationService.notification$.subscribe((data) => {
      this.showNotification = true;
      this.createComponent(data);
    });
  }

  createComponent(notification) {
    const factory = this.resolver.resolveComponentFactory(NotificationComponent);
    const componentRef = this.entry.createComponent(factory);
    componentRef.instance.data = {
      type: notification.type,
      message: notification.message
    }
    this.notificationsComponentsRef.push(componentRef);
    setTimeout(() => {
      componentRef.destroy();
    }, 5000);
  }
}
