import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NotificationService } from '../notification/notification.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    private notificationService: NotificationService
  ) { }

  async listUsers() {
    try {
      const users = await this.http.get(environment.api + 'user').toPromise();
      return users && users["data"] ? users["data"] : []
    } catch(e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.message
      });
    }
  }

  async createUser(user) {
    try {
      const result = await this.http.post(environment.api + 'user', user).toPromise();
      this.notificationService.notification$.next({
        type: 'success',
        message: 'Usuário criado com sucesso'
      });
      return result && result["data"] ? result["data"] : [];
    } catch(e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.message
      });
    }
  }

  async delete(id) {
    try {
      const user = await this.http.delete(environment.api + 'user/' + id).toPromise();
      this.notificationService.notification$.next({
        type: 'success',
        message: 'Usuário deletado com sucesso'
      });
      return user && user["data"] ? user["data"] : []
    } catch(e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.message
      });
    }
  }

  async login(email, password) {
    try {
      const result = await this.http.post(environment.api + 'user/login', {
        email,
        password
      }).toPromise();
      localStorage.setItem('token', result["data"]["token"]);
      localStorage.setItem('user', JSON.stringify(result["data"]["user"]));
      return result && result['data'] && result['data'] ? result['data'] : null;
    } catch(e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.message
      });
    }
  }

  getLogggedUser() {
    if(localStorage.getItem('user')) {
      return JSON.parse(localStorage.getItem('user'));
    }
    return null;
  }

  async sendPasswordRecoveryEmail(email) {
    try {
      const result = await this.http.post(environment.api + 'user/forgetpassword/' + email, {}).toPromise();
      this.notificationService.notification$.next({
        type: 'success',
        message: 'E-mail enviado com sucesso'
      });
      return result;
    } catch(e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.message
      });
    }
  }
}
