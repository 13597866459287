import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from './header/header.component';
import { WorkerEvaluationComponent } from './worker-evaluation/worker-evaluation.component';
import { ProfileComponent } from './profile/profile.component';
import { UserComponent } from './user/user.component';
import { AddUserComponent } from './user/add-user/add-user.component';
import { UserCardComponent } from './user/user-card/user-card.component';
import { AuthInterceptor } from './interceptor/auth.interceptor';
import { LoaderSpinnerComponent } from './loader-spinner/loader-spinner.component';
import { NotificationComponent } from './notification/notification.component';
import { RecoveryPasswordComponent } from './recovery-password/recovery-password.component';
import { AuthenticateChooseCompanyComponent } from './authenticate-choose-company/authenticate-choose-company.component';
import { BotComponent } from './bot/bot.component';
import { ModalComponent } from './modal/modal.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    HeaderComponent,
    WorkerEvaluationComponent,
    ProfileComponent,
    UserComponent,
    AddUserComponent,
    UserCardComponent,
    LoaderSpinnerComponent,
    NotificationComponent,
    RecoveryPasswordComponent,
    AuthenticateChooseCompanyComponent,
    BotComponent,
    ModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  entryComponents: [
    NotificationComponent,
    ModalComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
