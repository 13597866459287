import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from '../notification/notification.service';

@Injectable({
  providedIn: 'root'
})
export class BotService {

  constructor(
    private http: HttpClient,
    private notificationService: NotificationService
  ) { }

  async list() {
    try {
      const bots = await this.http.get(environment.api + 'bot').toPromise();
      return bots && bots["data"] ? bots["data"] : [];
    } catch(e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.message
      });
      return null;
    }
  }

  async getById(id) {
    try {
      const bots = await this.http.get(environment.api + 'bot/' + id).toPromise();
      return bots && bots["data"] ? bots["data"] : [];
    } catch(e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.message
      });
      return null;
    }
  }

  async getBotUserDetail(botId, startDate, endDate) {
    try {
      const bots = await this.http.get(`${environment.api}botUserDetail/${botId}?startDate=${startDate}&endDate=${endDate}`).toPromise();
      return bots && bots["data"] ? bots["data"] : [];
    } catch(e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.message
      });
      return null;
    }
  }

  async getBotSessions(botId, startDate, endDate, offset, limit) {
    try {
      const uri = `${environment.api}session/listInteractions/${botId}?startDate=${startDate}&endDate=${endDate}&offset=${offset}&limit=${limit}`;
      const bots = await this.http.get(uri).toPromise();
      return bots && bots["data"] ? bots["data"] : [];
    } catch(e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.message
      });
      return null;
    }
  }

  async countBotSessions(botId, startDate, endDate) {
    try {
      const uri = `${environment.api}session/countInteractions/${botId}?startDate=${startDate}&endDate=${endDate}`;
      const bots = await this.http.get(uri).toPromise();
      return bots && bots["data"] ? bots["data"] : 0;
    } catch(e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.message
      });
      return 0;
    }
  }

  async listBotFlows(botId, startDate, endDate, filterType) {
    try {
      const uri = `${environment.api}message/listBotFlows/${botId}?startDate=${startDate}&endDate=${endDate}&filterType=${filterType}`;
      const bots = await this.http.get(uri).toPromise();
      return bots && bots["data"] ? bots["data"] : [];
    } catch(e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.message
      });
      return null;
    }
  }

  async listNps(botId, startDate, endDate) {
    try {
      const uri = `${environment.api}nps/${botId}?startDate=${startDate}&endDate=${endDate}`;
      const bots = await this.http.get(uri).toPromise();
      return bots && bots["data"] ? bots["data"] : [];
    } catch(e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.message
      });
      return [];
    }
  }

  async listAttendances(botId, startDate, endDate) {
    try {
      const uri = `${environment.api}attendance/${botId}?startDate=${startDate}&endDate=${endDate}`;
      const bots = await this.http.get(uri).toPromise();
      return bots && bots["data"] ? bots["data"] : [];
    } catch(e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.message
      });
      return [];
    }
  }

  async listLostAttendances(botId, startDate, endDate, offset, limit) {
    try {
      const uri = `${environment.api}attendance/lostAttendance/${botId}/${offset}/${limit}?startDate=${startDate}&endDate=${endDate}`;
      const bots = await this.http.get(uri).toPromise();
      return bots && bots["data"] ? bots["data"] : [];
    } catch(e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.message
      });
      return [];
    }
  }

  async countLostAttendances(botId, startDate, endDate) {
    try {
      const uri = `${environment.api}attendance/count/lostAttendance/${botId}?startDate=${startDate}&endDate=${endDate}`;
      const bots = await this.http.get(uri).toPromise();
      return bots && bots["data"] ? bots["data"] : 0;
    } catch(e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.message
      });
      return 0;
    }
  }

  async countUsersSessions(botId, startDate, endDate, offset, limit) {
    try {
      const uri = `${environment.api}session/countUsersSessions/${botId}/${offset}/${limit}?startDate=${startDate}&endDate=${endDate}`;
      const bots = await this.http.get(uri).toPromise();
      return bots && bots["data"] ? bots["data"] : [];
    } catch(e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.message
      });
      return [];
    }
  }

  async countAllUsersSessions(botId, startDate, endDate) {
    try {
      const uri = `${environment.api}session/countAllUsersSessions/${botId}?startDate=${startDate}&endDate=${endDate}`;
      const bots = await this.http.get(uri).toPromise();
      return bots && bots["data"] ? bots["data"] : [];
    } catch(e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.message
      });
      return [];
    }
  }
  
  async listUserSessions(botId, userId) {
    try {
      const uri = `${environment.api}session/list/user/${botId}/${userId}`;
      const sessions = await this.http.get(uri).toPromise();
      return sessions && sessions["data"] ? sessions["data"] : [];
    } catch(e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.message
      });
      return [];
    }
  }
}
