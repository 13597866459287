import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BotService } from '../services/bot/bot.service';
import * as Chart from 'chart.js';
import * as moment from 'moment';

@Component({
  selector: 'app-bot',
  templateUrl: './bot.component.html',
  styleUrls: ['./bot.component.scss']
})
export class BotComponent implements OnInit {

  bot = null;

  selectedRange = 'week';

  rangeDate = {
    start: null,
    end: null
  };

  dayAccess = {
    monday: 0,
    tuesday: 0,
    wednesday: 0,
    thursday: 0,
    friday: 0,
    saturday: 0,
    sunday: 0
  };

  sessions = [];
  activeChartType = 'view';
  stateChart = null;

  states = [];

  showRangeOptions = false;

  userBotDetail = {
    uniqueUsers: 0,
    newUsers: 0,
    retentions: 0,
    sessions: 0,
    usageTime: 0,
    inactivities: 0,
    dailyAccess: 0
  };
  botSessions = [];
  countBotSessions = 0;
  countBotLostAttendances = 0;

  botFlows = [];

  nps = {
    promoters: 0,
    passives: 0,
    detractors: 0
  };

  attendances = [];
  lostAttendances = [];

  offset = 0;
  limit = 10;
  lostAttendanceOffset = 0;
  lostAttendanceLimit = 10;
  sessionsOffset = 0;
  sessionsLimit = 10;

  attendance = {
    total: 0,
    mostFrequentDay: null,
    outOfHour: 0
  };

  usersSessions = [];

  countAllUsersSessions = 0;

  userSessions = [];

  isUserSessionsModalOpened = false;

  rangeDateObj = {
    start: new Date(),
    end: new Date()
  };

  constructor(
    private route: ActivatedRoute,
    private botService: BotService,
    private router: Router
  ) { }

  async ngOnInit() {
    let start = new Date(2022, 1, 1, 0, 0, 0);
    let end = new Date(2022, 2, 15, 0, 0, 0);
    this.rangeDate.start = moment(start).startOf('week').toDate();
    this.rangeDate.end   = moment(end).endOf('week').toDate();
    const id = this.route.snapshot.paramMap.get('id');
    if(!id) {
      return this.router.navigate(['/dashboard']);
    }
    this.bot = await this.botService.getById(id);
    await this.loadData();
  }

  getSelectedRangeLabel() {
    switch(this.selectedRange) {
      case 'week': return 'Essa semana';
      case 'today': return 'Hoje';
      case 'month': return 'Esse mês';
      case 'lastThirty': return 'Últimos 30 dias';
      default: return 'Essa semana';
    }
  }

  async updateRange(range) {
    this.selectedRange = range;
    switch(range) {
      case 'today':
        this.rangeDate.start = moment().startOf('day').toDate();
        this.rangeDate.end = moment().endOf('day').toDate();
        break;
      case 'month':
        this.rangeDate.start = moment().startOf('month').toDate();
        this.rangeDate.end = moment().endOf('month').toDate();
        break;
      case 'week':
        this.rangeDate.start = moment().startOf('week').toDate();
        this.rangeDate.end   = moment().endOf('week').toDate();
        break;
    }
    await this.loadData();
  }

  async filter() {
    this.rangeDate.start = this.rangeDateObj.start;
    this.rangeDate.end = this.rangeDateObj.end;
    await this.loadData();
  }

  convertToHour(minutes) {
    let hour = minutes / 60
    return hour ? hour.toFixed(2) : 0;
  }

  async loadData() {
    this.states = [];

    this.listFlows();

    this.userBotDetail = await this.botService.getBotUserDetail(this.bot._id, this.rangeDate.start, this.rangeDate.end);

    this.dayAccess = {
      monday: this.userBotDetail.dailyAccess && this.userBotDetail.dailyAccess && this.userBotDetail.dailyAccess['1'] ? this.calcDailyAccessPercentage(this.userBotDetail.dailyAccess['1']) : 0,
      tuesday: this.userBotDetail.dailyAccess && this.userBotDetail.dailyAccess && this.userBotDetail.dailyAccess['2'] ? this.calcDailyAccessPercentage(this.userBotDetail.dailyAccess['2']) : 0,
      wednesday: this.userBotDetail.dailyAccess && this.userBotDetail.dailyAccess && this.userBotDetail.dailyAccess['3'] ? this.calcDailyAccessPercentage(this.userBotDetail.dailyAccess['3']) : 0,
      thursday: this.userBotDetail.dailyAccess && this.userBotDetail.dailyAccess && this.userBotDetail.dailyAccess['4'] ? this.calcDailyAccessPercentage(this.userBotDetail.dailyAccess['4']) : 0,
      friday: this.userBotDetail.dailyAccess && this.userBotDetail.dailyAccess && this.userBotDetail.dailyAccess['5'] ? this.calcDailyAccessPercentage(this.userBotDetail.dailyAccess['5']) : 0,
      saturday: this.userBotDetail.dailyAccess && this.userBotDetail.dailyAccess && this.userBotDetail.dailyAccess['6'] ? this.calcDailyAccessPercentage(this.userBotDetail.dailyAccess['6']) : 0,
      sunday: this.userBotDetail.dailyAccess && this.userBotDetail.dailyAccess && this.userBotDetail.dailyAccess['0'] ? this.calcDailyAccessPercentage(this.userBotDetail.dailyAccess['0']) : 0
    }

    this.botService.countBotSessions(this.bot._id, this.rangeDate.start, this.rangeDate.end).then((response) => {
      this.countBotSessions = response;
    });

    this.botService.getBotSessions(this.bot._id, this.rangeDate.start, this.rangeDate.end, this.offset, this.limit).then((response) => {
      this.botSessions = response;
    });

    this.botService.listNps(this.bot._id, this.rangeDate.start, this.rangeDate.end).then((npsRecords) => {
      this.nps = {
        passives: 0,
        detractors: 0,
        promoters: 0
      };
  
      npsRecords.forEach(nps => {
        if(nps.score < 3) {
          this.nps.detractors++;
        } else if(nps.score < 5) {
          this.nps.passives++;
        } else {
          this.nps.promoters++;
        }
      });
    });

    this.countBotLostAttendances = await this.botService.countLostAttendances(this.bot._id, this.rangeDate.start, this.rangeDate.end);

    this.botService.listAttendances(this.bot._id, this.rangeDate.start, this.rangeDate.end).then((response) => {
      this.attendances = response;
      this.fillAttendance();
    });

    this.botService.listLostAttendances(this.bot._id, this.rangeDate.start, this.rangeDate.end, this.lostAttendanceOffset, this.lostAttendanceLimit).then((response) => {
      this.lostAttendances = response;
    });

    this.botService.countUsersSessions(this.bot._id, this.rangeDate.start, this.rangeDate.end, this.sessionsOffset, this.sessionsLimit).then((response) => {
      this.usersSessions = response;
    })

    this.botService.countAllUsersSessions(this.bot._id, this.rangeDate.start, this.rangeDate.end).then((response) => {
      this.countAllUsersSessions = response.length;
    })
  }

  fillAttendance() {
    this.attendance.total = this.attendances.length;
    let attendanceByDay = {};
    this.attendances.forEach(attendance => {
      const day = new Date(attendance.start).getDay();
      if(day in attendanceByDay) {
        attendanceByDay[day]++;
      } else {
        attendanceByDay[day] = 0;
      }
    });
    let biggestDay = -1;
    Object.keys(attendanceByDay).forEach(day => {
      if(attendanceByDay[day] > biggestDay) {
        biggestDay = Number(day);
      }
    });
    switch(biggestDay) {
      case 0: this.attendance.mostFrequentDay = 'Domingo'; break;
      case 1: this.attendance.mostFrequentDay = 'Segunda'; break;
      case 2: this.attendance.mostFrequentDay = 'Terça'; break;
      case 3: this.attendance.mostFrequentDay = 'Quarta'; break;
      case 4: this.attendance.mostFrequentDay = 'Quinta'; break;
      case 5: this.attendance.mostFrequentDay = 'Sexta'; break;
      case 6: this.attendance.mostFrequentDay = 'Sábado'; break;
      default: this.attendance.mostFrequentDay = null; break;
    };
  }

  calcDailyAccessPercentage(dailyAccess) {
    if(!this.userBotDetail.sessions) return 0;
    return Math.round((dailyAccess / this.userBotDetail.sessions) * 100);
  }

  async listFlows() {
     this.botService.listBotFlows(this.bot._id, this.rangeDate.start, this.rangeDate.end, this.activeChartType).then((response) => {
      this.botFlows = response;
      this.states = this.botFlows.map(f => {
        return {
        state: f.state,
        count: f.count
      }});
      this.plotCharts();
    })
  }

  async toggleChartType(type) {
    this.activeChartType = type;
    await this.listFlows();
  }

  plotCharts() {
    this.plotStateChart();
  }

  plotStateChart() {
    let canvas = document.getElementById('interaction-chart') as HTMLCanvasElement;
    if(this.stateChart != null){
      this.stateChart.destroy();
    }
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    canvas.height = 250;
    const data = {
      labels: this.states.map(s => s.state),
      datasets: [
        {
          label: 'Fluxos',
          data: this.states.map(s => s.count),
          backgroundColor: this.getStateChartBgc(),
          borderRadius: 20,
        }
      ]
    };
    const config = {
      type: 'bar',
      data: data,
      options: {
        maintainAspectRatio: false,
        cornerRadius: 8,
        height: 250,
        responsive: true,
        scales: {
          yAxes: [{
              ticks: {
                  beginAtZero: true
              }
          }]
        },
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: false,
            text: 'Fluxos'
          }
        }
      },
    };
    this.stateChart = new Chart(ctx, config);
  }

  getStateChartBgc() {
    switch(this.activeChartType) {
      case 'view':
        return '#553AFE';
      case 'abandoned':
        return '#06C1F6';
      case 'notMatch':
        return '#FF8662';
      default:
        return '#553AFE';
    }
  }

  calcMissingInteractions() {
    return this.countBotSessions - (this.offset + this.limit);
  }

  calcMissingLostAttendances() {
    return this.countBotLostAttendances - (this.lostAttendanceOffset + this.lostAttendanceLimit);
  }

  calcMissingSessions() {
    return this.countAllUsersSessions - (this.sessionsOffset + this.sessionsLimit);
  }

  async loadMore() {
    this.offset = this.offset + this.limit;
    const sessions = await this.botService.getBotSessions(this.bot._id, this.rangeDate.start, this.rangeDate.end, this.offset, this.limit);
    if(sessions && sessions.length > 0) {
      this.botSessions = this.botSessions.concat(sessions);
    }
  }

  async loadMoreLostAttendances() {
    this.lostAttendanceOffset = this.lostAttendanceOffset + this.lostAttendanceLimit;
    const attendances = await this.botService.listLostAttendances(this.bot._id, this.rangeDate.start, this.rangeDate.end, this.lostAttendanceOffset, this.lostAttendanceLimit);
    if(attendances && attendances.length > 0) {
      this.lostAttendances = this.lostAttendances.concat(attendances);
    }
  }

  async loadMoreSessions() {
    this.sessionsOffset = this.sessionsOffset + this.sessionsLimit;
    const sessions = await this.botService.countUsersSessions(this.bot._id, this.rangeDate.start, this.rangeDate.end, this.sessionsOffset, this.sessionsLimit);
    if(sessions && sessions.length > 0) {
      this.usersSessions = this.usersSessions.concat(sessions);
    }
  }

  async listUserSessions(userId) {
    this.userSessions = await this.botService.listUserSessions(this.bot._id, userId);
    this.isUserSessionsModalOpened = true;
  }

}
