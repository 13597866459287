import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ProfileService } from 'src/app/services/profile/profile.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  @Output() addUser = new EventEmitter<any>();

  profiles = [];

  user = {
    name: null,
    lastName: null,
    email: null,
    profileId: null
  };

  constructor(
    private profileService: ProfileService
  ) { }

  async ngOnInit() {
    this.profiles = await this.profileService.listProfiles();
  }

  add() {
    this.addUser.emit(this.user);
  }

}
