import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationService } from '../services/notification/notification.service';

@Component({
  selector: 'app-authenticate-choose-company',
  templateUrl: './authenticate-choose-company.component.html',
  styleUrls: ['./authenticate-choose-company.component.scss']
})
export class AuthenticateChooseCompanyComponent implements OnInit {

  @Input() companies: any;
  @Output() onAuthenticateCompany = new EventEmitter<string>();

  company = null;

  constructor(
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
  }

  async continue() {
    if(!this.company) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'Escolha uma empresa para prosseguir'
      });
      return false;
    }
    this.onAuthenticateCompany.emit(this.company);
  }
}
