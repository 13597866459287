import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user/user.service';
import { Router } from '@angular/router';
import { NotificationService } from '../services/notification/notification.service';

@Component({
  selector: 'app-recovery-password',
  templateUrl: './recovery-password.component.html',
  styleUrls: ['./recovery-password.component.scss']
})
export class RecoveryPasswordComponent implements OnInit {

  email = null;

  constructor(
    private userService: UserService,
    private router: Router,
    private notificationService: NotificationService
  ) { }

  ngOnInit(
  ) {
  }

  async sendPasswordRecoveryEmail() {
    if(this.email) {
      await this.userService.sendPasswordRecoveryEmail(this.email);
      this.goToLogin();
    } else {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'Preencha o e-mail'
      });
    }
  }

  goToLogin() {
    this.router.navigate(['/login']);
  }

}
