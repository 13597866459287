import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services/user/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  email = null;
  password = null;

  isLogged = false;

  companies = [];

  recoverPasswordEnabled = false;

  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit() {
  }

  async login() {
    if(this.email && this.password) {
      const result = await this.userService.login(this.email, this.password);
      if(result) {
        this.router.navigate(['/dashboard']);
      }
    }
  }

  toggleRecoverPassword() {
    this.recoverPasswordEnabled = !this.recoverPasswordEnabled;
  }

  async recoverPassword() {
    await this.userService.sendPasswordRecoveryEmail(this.email);
    this.recoverPasswordEnabled = true;
  }

}
