import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user/user.service';
import { ProfileService } from '../services/profile/profile.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  users = [];
  profiles = [];

  isModalOpened = false;

  constructor(
    private userService: UserService,
    private profileService: ProfileService
  ) { }

  async ngOnInit() {
    await this.loadProfiles();
    await this.loadUsers();
    console.log('profiles');
    console.log(this.profiles);
  }

  async loadProfiles() {
    this.profiles = await this.profileService.listProfiles();
  }

  async loadUsers() {
    this.users = await this.userService.listUsers();
    this.users.map(user => {
      const profile = this.profiles.find(p => p._id === user.profileId);
      user.profile = profile;
    });
  }

  openNewUserModal() {
    this.isModalOpened = true;
  }

  async onAddUser(user) {
    await this.userService.createUser(user);
    await this.loadUsers();
  }

  async onDeleteUser(id) {
    await this.userService.delete(id);
    await this.loadUsers();
  }

}
