import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BotService } from '../services/bot/bot.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  dashboard = {
    online: 0,
    offline: 0,
    repair: 0,
    install: 0
  };

  bots = [];

  constructor(
    private botService: BotService,
    private router: Router
  ) { }

  async ngOnInit() {
    this.bots = await this.botService.list();
  }

  navigateTo(route) {
    this.router.navigate([route]);
  }

  openBot(botId) {
    this.router.navigate(['/bot/' + botId]);
  }

}
