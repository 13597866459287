import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProfileComponent } from './profile/profile.component';
import { UserComponent } from './user/user.component';
import { AuthGuardService } from './services/auth/auth-guard.service';
import { RecoveryPasswordComponent } from './recovery-password/recovery-password.component';
import { AuthenticateChooseCompanyComponent } from './authenticate-choose-company/authenticate-choose-company.component';
import { BotComponent } from './bot/bot.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'recover-password', component: RecoveryPasswordComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuardService] },
  { path: 'profiles', component: ProfileComponent, canActivate: [AuthGuardService] },
  { path: 'users', component: UserComponent, canActivate: [AuthGuardService] },
  { path: 'autenticar', component: AuthenticateChooseCompanyComponent, canActivate: [AuthGuardService] },
  { path: 'bot/:id', component: BotComponent, canActivate: [AuthGuardService] },
  { path: '**', component: DashboardComponent, canActivate: [AuthGuardService] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
