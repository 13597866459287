import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../services/profile/profile.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  profiles = [];

  constructor(
    private profileService: ProfileService
  ) { }

  async ngOnInit() {
    await this.loadProfiles();
  }

  async loadProfiles() {
    const profiles = await this.profileService.listProfiles();
    this.profiles = profiles;
  }

  async deleteProfile(id) {
    await this.profileService.delete(id);
    await this.loadProfiles();
  }

}
