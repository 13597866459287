import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from '../notification/notification.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(
    private http: HttpClient,
    private notificationService: NotificationService
  ) { }

  async listProfiles() {
    try {
      const profiles = await this.http.get(environment.api + 'profile').toPromise();
      return profiles && profiles["data"] ? profiles["data"] : []
    } catch(e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.message
      });
    }
  }

  async createProfile(profile) {
    try {
      const result = await this.http.post(environment.api + 'profile', profile).toPromise();
      this.notificationService.notification$.next({
        type: 'success',
        message: 'Perfil criado com sucesso'
      });
      return result && result["data"] ? result["data"] : [];
    } catch(e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.message
      });
    }
  }

  async delete(id) {
    try {
      const profile = await this.http.delete(environment.api + 'profile/' + id).toPromise();
      this.notificationService.notification$.next({
        type: 'success',
        message: 'Perfil deletado com sucesso'
      });
      return profile && profile["data"] ? profile["data"] : []
    } catch(e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.message
      });
    }
  }
}
